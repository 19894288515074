import {
    FETCH_CARS,
    FETCH_CARS_SUCCESS,
    FETCH_CARS_FAILED,
    EDIT_CAR
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, set, child, remove, push } from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchCars = () => (dispatch) => {
  
    const {
        carsRef
    } = firebase;
  
    dispatch({
      type: FETCH_CARS,
      payload: null
    });

    const userInfo = store.getState().auth.profile;

    onValue(carsRef(userInfo.uid, userInfo.usertype), snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_CARS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_CARS_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    });
  };
  
  export const editCar = (car, method,type) => async (dispatch) => {
    const {
      singleUserRef,
      carAddRef, 
      carEditRef,
      carImage,
      carInsuranceImage,
      carRegImage
    } = firebase;

    dispatch({
      type: EDIT_CAR,
      payload: { method, car }
    });
    if (method === 'Add') {
        push(carAddRef, car);
    } else if (method === 'Delete') {
        remove(carEditRef(car.id));
    } else if (method === 'UpdateImage') {
      
      let data = car;
      if (type === 'carimage') {
      await uploadBytesResumable(carImage(car.id),car.car_image);
      let image = await getDownloadURL(carImage(car.id));
      data.car_image = image;
      } else  if (type === 'insurance') {
      await uploadBytesResumable(carInsuranceImage(car.id), car.Insuranceimage);
      let image1 = await getDownloadURL(carInsuranceImage(car.id));
        data.car_insurance = image1;
      } else if (type === 'reg') {
        await uploadBytesResumable(carRegImage(car.id), car.Regimage);
    let image2 = await getDownloadURL(carRegImage(car.id));
      data.car_reg = image2;
      }

      set(carEditRef(car.id), data);
      if(car.active && car.driver){
        update(singleUserRef(car.driver), {
          updateAt: new Date().getTime(),
          car_image: image,
          car_insurance: image1,
          car_reg: image2
        });
      }   
    }
     else {
        set(carEditRef(car.id),car);
    }
  }

  export const updateUserCarWithImage = (newData, blob, blob1,blob2) => (dispatch) => {
    const { auth, carAddRef, singleUserRef, carImage, carRegImage, carInsuranceImage } = firebase;
    var carId = push(carAddRef).key;

    const uploadImage = (ref, blob) => {
        return uploadBytesResumable(ref, blob).then(() => {
            blob.close();
            return getDownloadURL(ref);
        });
    };

    Promise.all([
        uploadImage(carImage(carId), blob),
        uploadImage(carRegImage(carId), blob1),
        uploadImage(carInsuranceImage(carId), blob2)
    ]).then(([carImageUrl, carRegUrl,carInsuranceUrl]) => {
        newData.car_image = carImageUrl;
        newData.car_reg = carRegUrl;
        newData.car_insurance=carInsuranceUrl;

        set(child(carAddRef, carId), newData);
        
        if (newData.active) {
            let updateData = {
                carType: newData.carType,
                vehicleNumber: newData.vehicleNumber,
                vehicleMake: newData.vehicleMake,
                vehicleModel: newData.vehicleModel,
                other_info: newData.other_info,
                car_image: carImageUrl,
                car_reg: carRegUrl,
                car_insurance: carInsuranceUrl,
                carApproved: newData.approved,
                updateAt: new Date().getTime()
            };
            update(singleUserRef(auth.currentUser.uid), updateData);
        }
    }).catch((error) => {
        console.error("Error updating car images: ", error);
        Alert.alert(t('alert'), t('image_upload_error'));
    });
};


  // export const updateUserCarWithImage = (newData, blob) => (dispatch) => {
  //   const {
  //     auth,
  //     carAddRef,
  //     singleUserRef,
  //     carImage
  //   } = firebase;

  //   var carId = push(carAddRef).key;

  //   uploadBytesResumable(carImage(carId),blob).then(() => {
  //     blob.close()
  //     return getDownloadURL(carImage(carId))
  //   }).then((url) => {
  //     newData.car_image = url;
  //     set(child(carAddRef, carId),newData )
  //     if(newData.active){
  //       let updateData = {
  //         carType: newData.carType,
  //         vehicleNumber: newData.vehicleNumber,
  //         vehicleMake: newData.vehicleMake,
  //         vehicleModel: newData.vehicleModel,
  //         other_info: newData.other_info,
  //         car_image:url,
  //         carApproved: newData.approved,
  //         updateAt: new Date().getTime()
  //       };
  //       update(singleUserRef(auth.currentUser.uid),updateData);
  //     }
  //   })
  // };

//   export const updateUserCarWithImages = (newData, blobs) => (dispatch) => {
//     const {
//         auth,
//         carAddRef,
//         singleUserRef,
//         carImage,
//         carInsuranceImage,
//         carRegImage
//     } = firebase;

//     var carId = push(carAddRef).key;

//     const uploadImage = (ref, blob) => {
//         return uploadBytesResumable(ref, blob).then(() => {
//             blob.close();
//             return getDownloadURL(ref);
//         });
//     };

//     Promise.all([
//         uploadImage(carImage(carId), blobs.car_image),
//         uploadImage(carInsuranceImage(carId), blobs.car_insurance),
//         uploadImage(carRegImage(carId), blobs.car_reg)
//     ]).then(([carImageUrl, carInsuranceUrl, carRegUrl]) => {
//         newData.car_image = carImageUrl;
//         newData.car_insurance = carInsuranceUrl;
//         newData.car_reg = carRegUrl;
        
//         set(child(carAddRef, carId), newData);
        
//         if (newData.active) {
//             let updateData = {
//                 carType: newData.carType,
//                 vehicleNumber: newData.vehicleNumber,
//                 vehicleMake: newData.vehicleMake,
//                 vehicleModel: newData.vehicleModel,
//                 other_info: newData.other_info,
//                 car_image: carImageUrl,
//                 car_insurance: carInsuranceUrl,
//                 car_reg: carRegUrl,
//                 carApproved: newData.approved,
//                 updateAt: new Date().getTime()
//             };
//             update(singleUserRef(auth.currentUser.uid), updateData);
//         }
//     }).catch((error) => {
//         console.error("Error updating car images: ", error);
//         Alert.alert(t('alert'), t('image_upload_error'));
//     });
// };

  // export const updateUserCarWithImage = (newData, blobs) => (dispatch) => {
  //   const {
  //     auth,
  //     carAddRef,
  //     singleUserRef,
  //     carImage
  //   } = firebase;

  //   var carId = push(carAddRef).key;

  //   uploadBytesResumable(carImage(carId),blobs).then(() => {
  //     blob.close()
  //     return getDownloadURL(carImage(carId))
  //   }).then((url) => {
  //     newData.car_image = url;
  //     set(child(carAddRef, carId),newData )
  //     if(newData.active){
  //       let updateData = {
  //         carType: newData.carType,
  //         vehicleNumber: newData.vehicleNumber,
  //         vehicleMake: newData.vehicleMake,
  //         vehicleModel: newData.vehicleModel,
  //         other_info: newData.other_info,
  //         car_image:url,
  //         carApproved: newData.approved,
  //         updateAt: new Date().getTime()
  //       };
  //       update(singleUserRef(auth.currentUser.uid),updateData);
  //     }
  //   })
  // };

  // export const updateUserCarWithImage = (newData, blob, blob2, blob3) => async (dispatch) => {
  //   const {
  //     auth,
  //     carAddRef,
  //     singleUserRef,
  //     carImage,
  //     carInsuranceImage,
  //     carRegImage
  //   } = firebase;
  
  //   const carId = push(carAddRef).key;
  
  //   try {
  //     // Upload car image
  //     await uploadBytesResumable(carImage(carId), blob);
  //     blob.close();
  //     const carImageUrl = await getDownloadURL(carImage(carId));
  //     newData.car_image = carImageUrl;
  
  //     // Upload car registration
  //     await uploadBytesResumable(carRegImage(carId), blob2);
  //     blob2.close();
  //     const carRegUrl = await getDownloadURL(carRegImage(carId));
  //     newData.car_reg = carRegUrl;
  
  //     // Upload car insurance
  //     await uploadBytesResumable(carInsuranceImage(carId), blob3);
  //     blob3.close();
  //     const carInsuranceUrl = await getDownloadURL(carInsuranceImage(carId));
  //     newData.car_insurance = carInsuranceUrl;
  
  //     // Set car data in Firebase
  //     await set(child(carAddRef, carId), newData);
  
  //     if (newData.active) {
  //       const updateData = {
  //         carType: newData.carType,
  //         vehicleNumber: newData.vehicleNumber,
  //         vehicleMake: newData.vehicleMake,
  //         vehicleModel: newData.vehicleModel,
  //         other_info: newData.other_info,
  //         car_image: carImageUrl,
  //         car_reg: carRegUrl,
  //         car_insurance: carInsuranceUrl,
  //         carApproved: newData.approved,
  //         updateAt: new Date().getTime()
  //       };
  //       await update(singleUserRef(auth.currentUser.uid), updateData);
  //     }
  //   } catch (error) {
  //     console.error("Error updating car with image: ", error);
  //   }
  // };